<template>
   <div id="preloader">
      <div id="status">
        <div class="spinner">
          <i class="ri-loader-line spin-icon"></i>
        </div>
      </div>
    </div>
</template>
<script >
export default {
  
    
    
}
</script>